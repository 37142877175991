/**
 * Logged in User Role
 *  - Update roles based on your app's needs!
 */
export enum UserRole {
  User = 'User',
  Manager = 'Manager',
  Admin = 'Admin',
  Sales = 'Sales',
}
