import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { LicenseManager } from 'ag-grid-enterprise';

import 'hammerjs';

LicenseManager.setLicenseKey(
  'CompanyName=XPO Logistics (Portland, OR),' +
    'LicensedGroup=xpo-ltl,' +
    'LicenseType=MultipleApplications,' +
    'LicensedConcurrentDeveloperCount=10,' +
    'LicensedProductionInstancesCount=0,' +
    'AssetReference=AG-008317,' +
    'ExpiryDate=27_May_2021_[v2]_MTYyMjA3MDAwMDAwMA==aae7ff28c3708c22eeef5223373c09ad'
);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
