import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  moduleId: module.id,
  selector: 'status-cell-renderer',
  templateUrl: 'status-cell-renderer.component.html',
  styleUrls: ['status-cell-renderer.component.scss'],
})
export class StatusCellRendererComponent implements ICellRendererAngularComp {
  status = '';

  agInit(params: any): void {
    this.status = params.value;
  }

  refresh(): boolean {
    return true;
  }
}
