import { NgModule } from '@angular/core';
import {
  ActionBarCenterContent,
  ActionBarComponent,
  ActionBarLeftContent,
  ActionBarRightContent,
} from './components/action-bar/action-bar.component';
import { SharedVendorModule } from '../shared-vendor.module';
import { LabelMetricsComponent } from './components/label-metrics/label-metrics.component';
import { XpoCardModule, XpoTabsModule, XpoButtonModule, XpoIconModule, XpoDialogModule } from '@xpo/ngx-core';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core';
import { CommonModule } from '@angular/common';
import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import { XpoHighchartsChartModule } from '@xpo/ngx-core-highcharts';
import HC_More from 'highcharts/highcharts-more';
import HC_Annotations from 'highcharts/modules/annotations';
import HC_SolidGauge from 'highcharts/modules/solid-gauge';
import { LabelChartMetricsComponent } from './components/label-chart-metrics/label-chart-metrics.component';
import { AssignedCellRendererComponent } from './cell-renderer/assigned-cell-renderer/assigned-cell-renderer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LabelTypeCellRendererComponent } from './cell-renderer/label-type-cell-renderer/label-type-cell-renderer.component';
import { CoreModule } from '@angular/flex-layout';
import { StatusCellRendererComponent } from './cell-renderer/status-cell-renderer/status-cell-renderer.component';
import { NumericCellEditorComponent } from './cell-editors/numeric-cell-editor/numeric-cell-editor.component';
import { TextMaskModule } from 'angular2-text-mask';
import { LinkCellRendererComponent } from './cell-renderer/link-cell-renderer/link-cell-renderer.component';
import { RouterModule } from '@angular/router';
import { GridActionsRendererComponent } from './cell-renderer/grid-actions-renderer/grid-actions-renderer.component';
import { ConversationComponent } from './components/conversation/conversation.component';
import { MadInputComponent } from './components/mad-input/mad-input.component';
import { RequestStatusComponent } from './components/request-status/request-status.component';
import { SicInputComponent } from './components/sic-input/sic-input.component';
import { GroupCellRendererComponent } from './cell-renderer/group-cell-renderer/group-cell-renderer.component';
import { HoldNotificationComponent } from './components/hold-notification/hold-notification.component';
import { TooltipRendererComponent } from './cell-renderer/tooltip-renderer/tooltip-renderer.component';
import { FooterComponent } from './components/footer/footer.component';
import { HelpModalComponent } from './components/footer/help-modal.component';
import { RefreshHelperComponent } from './components/refresh-helper/refresh-helper.component';
import { NoteComponent } from './components/note/note.component';
import { TextEditorComponent } from './cell-editors/text-editor/text-editor.component';
import { TextCollapseComponent } from './components/text-collapse/text-collapse.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { OnlyNumbersDirective } from './directives/only-numbers/only-numbers.directive';
import { MetricsProjectionComponent } from './components/metrics-projection/metrics-projection.component';
import { CustomPaginationComponent } from './cell-renderer/custom-pagination/custom-pagination.component';
import { QttyCellRendererComponent } from './cell-renderer/qtty-cell-renderer/qtty-cell-renderer.component';

@NgModule({
  declarations: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    LabelMetricsComponent,
    LabelChartMetricsComponent,
    AssignedCellRendererComponent,
    LabelTypeCellRendererComponent,
    StatusCellRendererComponent,
    NumericCellEditorComponent,
    LinkCellRendererComponent,
    GridActionsRendererComponent,
    ConversationComponent,
    MadInputComponent,
    RequestStatusComponent,
    SicInputComponent,
    GroupCellRendererComponent,
    HoldNotificationComponent,
    TooltipRendererComponent,
    QttyCellRendererComponent,
    FooterComponent,
    HelpModalComponent,
    RefreshHelperComponent,
    NoteComponent,
    TextEditorComponent,
    TextCollapseComponent,
    DateRangePickerComponent,
    CalendarComponent,
    OnlyNumbersDirective,
    MetricsProjectionComponent,
    CustomPaginationComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    XpoCardModule,
    SharedVendorModule,
    HighchartsChartModule,
    XpoHighchartsChartModule,
    TextMaskModule,
    RouterModule,
    XpoTabsModule,
    XpoButtonModule,
    XpoDialogModule,
    XpoIconModule,
    XpoSnackBarModule,
    ReactiveFormsModule,
  ],
  exports: [
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    LabelMetricsComponent,
    LabelChartMetricsComponent,
    StatusCellRendererComponent,
    ConversationComponent,
    MadInputComponent,
    SicInputComponent,
    HoldNotificationComponent,
    FooterComponent,
    RefreshHelperComponent,
    NoteComponent,
    TextCollapseComponent,
    DateRangePickerComponent,
    OnlyNumbersDirective,
    MetricsProjectionComponent,
    CustomPaginationComponent,
  ],
  entryComponents: [
    AssignedCellRendererComponent,
    LabelTypeCellRendererComponent,
    QttyCellRendererComponent,
    StatusCellRendererComponent,
    NumericCellEditorComponent,
    LinkCellRendererComponent,
    GridActionsRendererComponent,
    GroupCellRendererComponent,
    TooltipRendererComponent,
    HelpModalComponent,
    TextEditorComponent,
  ],
})
export class SharedModule {
  constructor() {
    HC_More(Highcharts);
    HC_SolidGauge(Highcharts);
    HC_Annotations(Highcharts);
  }
}
