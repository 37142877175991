import { Component } from '@angular/core';

@Component({
  selector: 'app-error',
  template: `
    <div class="err-body" fxLayout="column" fxLayoutAlign="center center">
      <mat-icon class="err-icon"> warning </mat-icon>
      <h1>HTTP 400 or 500 error</h1>
      <p>
        The page you're looking for may have moved, its name may have changed, or it may be temporarily unavailable.
        Please refresh or
        <a href="mailto:LTLApplicationSupport@xpo.com">contact support</a>
      </p>
    </div>
  `,
  styles: [
    'h1 { font-size: 30px } a { cursor: pointer } .err-body { margin-top: 20vh } .err-icon { height: 100px; width: 100px; font-size: 100px }',
  ],
})
export class ErrorComponent {}
