import { Injectable } from '@angular/core';
import { CacheStateValue } from '../../shared/models/cache-state';
import { DashboardType } from '../../shared/enums/dashboard-type.enum';

@Injectable({
  providedIn: 'root',
})
export class CacheHelperService {
  private states: { [key: string]: CacheStateValue } = {};
  private expirationMarks = {};
  private selectedTab: { [key: string]: { [key: string]: string | null } } = {};

  constructor() {}

  recordState(state: CacheStateValue): void {
    sessionStorage.setItem(state.dashboardType, JSON.stringify(state));
    this.states[state.dashboardType] = state;

    this.setExpiry(state.dashboardType);
  }

  getCurrentState(dashboardType: DashboardType): CacheStateValue {
    let currentDashboardCache: CacheStateValue;
    if (this.states[dashboardType]) {
      currentDashboardCache = this.states[dashboardType];
    } else {
      currentDashboardCache = JSON.parse(sessionStorage.getItem(dashboardType));
      this.states[dashboardType] = currentDashboardCache;
      this.setExpiry(dashboardType);
    }

    return currentDashboardCache;
  }

  recordTab(dashboardType: DashboardType, selectedTab) {
    sessionStorage.setItem(dashboardType, JSON.stringify({ tab: selectedTab }));
    this.selectedTab[dashboardType] = { tab: selectedTab };
    this.setExpiry(dashboardType);
  }

  getCurrentSelectedTab(dashboardType: DashboardType): { [key: string]: string } {
    if (this.states[dashboardType]) {
      return this.selectedTab[dashboardType];
    } else {
      const sessionData = JSON.parse(sessionStorage.getItem(dashboardType));
      this.selectedTab[dashboardType] = sessionData;
      this.setExpiry(dashboardType);
      return sessionData;
    }
  }

  private setExpiry(dashboardType: DashboardType) {
    if (this.expirationMarks[dashboardType]) {
      clearTimeout(this.expirationMarks[dashboardType]);
    }
    this.expirationMarks[dashboardType] = setTimeout(() => {
      sessionStorage.removeItem(dashboardType);
      delete this.states[dashboardType];
      delete this.selectedTab[dashboardType];
      delete this.expirationMarks[dashboardType];
    }, 300000);
  }
}
