import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ProPadTypeCd } from '@xpo-ltl/sdk-common';

@Component({
  moduleId: module.id,
  selector: 'label-type-cell-renderer',
  templateUrl: 'label-type-cell-renderer.component.html',
  styleUrls: ['label-type-cell-renderer.component.scss'],
})
export class LabelTypeCellRendererComponent implements ICellRendererAngularComp {
  labelType = {
    isBlueYellow: false,
    isBlue: false,
    isYellow: false,
  };
  labelTypeName = '';
  isGroup = false;
  labelCount = 0;

  constructor() {}

  agInit(params: any): void {
    this.isGroup = params.node.group;
    if (!this.isGroup) {
      const labelType = params.data.labelType || params.data.labelPadTypeCd;
      switch (labelType) {
        case ProPadTypeCd.CA_BLUE_YELLOW:
          this.labelType.isBlueYellow = true;
          this.labelTypeName = 'CA, Blue & Yellow';
          break;
        case ProPadTypeCd.US_BLUE_YELLOW:
          this.labelType.isBlueYellow = true;
          this.labelTypeName = 'US, Blue & Yellow';
          break;
        case ProPadTypeCd.CA_BLUE:
          this.labelType.isBlue = true;
          this.labelTypeName = 'CA, Blue';
          break;
        case ProPadTypeCd.US_BLUE:
          this.labelType.isBlue = true;
          this.labelTypeName = 'US, Blue';
          break;
        case ProPadTypeCd.YELLOW:
          this.labelType.isYellow = true;
          this.labelTypeName = 'Yellow';
          break;

        default:
          break;
      }
    } else {
      this.labelCount = params.data.assignmentRequestDetail.length;
    }
  }

  /* istanbul ignore next */
  refresh(): boolean {
    return false;
  }
}
