import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RefreshService {
  private refreshSubject = new BehaviorSubject<void>(null);

  constructor() {}

  refresh() {
    return this.refreshSubject;
  }

  notifyRefresh() {
    this.refreshSubject.next();
  }
}
