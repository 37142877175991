import { Injectable } from '@angular/core';
import { catchError, takeUntil } from 'rxjs/operators';
import { Observable, of, BehaviorSubject } from 'rxjs';
import {
  ProNumberReengineeringApiService,
  ListCustomAssignmentLocationResp,
} from '@xpo-ltl/sdk-pronumberreengineering';
import {
  GetZoneAndSatelliteBySicQuery,
  GetZoneAndSatelliteBySicPath,
  LocationApiService,
} from '@xpo-ltl-2.0/sdk-location';

@Injectable({ providedIn: 'root' })
export class LocationService {
  customAssignmentLabel = [];
  customAssignmentElectronic = [];

  constructor(
    private proNumberReengineeringApiService: ProNumberReengineeringApiService,
    private locationApiService: LocationApiService
  ) {}

  loadCustomAssignmentLocation(userSic) {
    this.proNumberReengineeringApiService
      .ListCustomAssignmentLocation()
      .pipe(catchError(this.handleError))
      .subscribe((data: ListCustomAssignmentLocationResp) => {
        if (data.customAssignmentLocations) {
          this.saveCustomAssignmentLocation(data, userSic);
        }
      });
  }

  private saveCustomAssignmentLocation(data, userSic) {
    data.customAssignmentLocations.forEach((element) => {
      const current = element.split(' | ');
      const type = current[0];
      const hostSic = current[1];
      const allowedSic = current[2];
      if (type === 'E' && userSic === hostSic) {
        this.getZones(allowedSic);
      } else if (type === 'L' && userSic === hostSic) {
        this.customAssignmentLabel.push(allowedSic);
      }
    });
  }

  private getZones(allowedSic) {
    const queryParams = new GetZoneAndSatelliteBySicQuery();
    queryParams.zoneInd = true;
    const path = {
      sicCd: allowedSic,
    } as GetZoneAndSatelliteBySicPath;
    this.locationApiService
      .getZoneAndSatelliteBySic(path, queryParams)
      .pipe(catchError(this.handleError))
      .subscribe((response: any) => {
        this.customAssignmentElectronic.push({ allowedSic: allowedSic, zones: response.zoneSatelliteInfo });
      });
  }

  getCustomAssignmentLocationLabel() {
    return this.customAssignmentLabel;
  }

  getCustomAssignmentLocationElectronic() {
    return this.customAssignmentElectronic;
  }

  handleError(err: any) {
    return of(null);
  }
}
