import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SdkLocationModule } from '@xpo-ltl-2.0/sdk-location';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule } from '@xpo-ltl/config-manager';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { LoginModule } from '@xpo-ltl/login';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { SdkAppMetadataModule } from '@xpo-ltl/sdk-appmetadata';
import { SdkUserPreferenceModule } from '@xpo-ltl/sdk-userpreference';
import { XPO_AUTH_CONFIG, XpoAuthConfig } from '@xpo/ngx-auth';
import {
  XpoButtonModule,
  XpoCardModule,
  XpoDialogModule,
  XpoFeedbackModule,
  XpoPageNotFoundRoutingModule,
  XpoShellModule,
  XpoSnackBarModule,
  XpoMessagingPopoverModule,
} from '@xpo-ltl/ngx-ltl-core';
import { NotificationComponent } from './app-notification.component';
import { ErrorComponent } from './app-error.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DialogModule } from './dialogs/dialog.module';
import { LandingPageModule } from './landing-page/landing-page.module';
import { SharedModule } from './shared/shared.module';
import { FormsModule } from '@angular/forms';
import { SharedVendorModule } from './shared-vendor.module';
import { ProNumberReengineeringApiService } from '@xpo-ltl/sdk-pronumberreengineering';
import { HumanResourceApiService } from '@xpo-ltl/sdk-humanresource';
import { TitleCasePipe } from '@angular/common';
import { CustomerApiService } from '@xpo-ltl/sdk-customer';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandlerInterceptor } from './core/interceptors/error-handler.interceptor';
import { ErrorResolver } from './app-error.resolver';
import { MatTabsModule } from '@angular/material';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { SupportedAppEnum, XpoMaintAppConfig, XpoMaintenanceModule } from '@xpo-ltl/maint-ui';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [AppComponent, NotificationComponent, ErrorComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    FormsModule,
    LoginModule,
    ConfigManagerModule,
    DialogModule,
    SharedModule,
    BrowserAnimationsModule,
    XpoButtonModule,
    XpoCardModule,
    XpoDialogModule,
    XpoShellModule,
    XpoFeedbackModule,
    XpoSnackBarModule,
    SdkAppMetadataModule,
    SdkUserPreferenceModule,
    SdkLocationModule,
    LandingPageModule,
    XpoMessagingPopoverModule,
    SharedVendorModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    XpoMaintenanceModule.initialize(<XpoMaintAppConfig>{ appName: SupportedAppEnum.PRO_INVENT }),
    AppRoutingModule,
    XpoPageNotFoundRoutingModule,
    MatTabsModule,
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
    DataApiService,
    NotificationService,
    ProNumberReengineeringApiService,
    HumanResourceApiService,
    TitleCasePipe,
    CustomerApiService,
    ErrorResolver,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
