import { Router } from '@angular/router';
import { ProNumberReengineeringApiService } from '@xpo-ltl/sdk-pronumberreengineering';
import { Observable, EMPTY, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CustomerApiService } from '@xpo-ltl/sdk-customer';

@Injectable()
export class ErrorResolver {
  constructor(
    private router: Router,
    private proNumberService: ProNumberReengineeringApiService,
    private customerApiService: CustomerApiService
  ) {}

  resolve(): Observable<any> {
    return forkJoin([this.proNumberService.healthCheck(), this.customerApiService.healthCheck()]).pipe(
      catchError(() => {
        this.router.navigate(['/error']);
        return EMPTY;
      })
    );
  }
}
