import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ProInventoryService } from '../../../core/services/pro-inventory.service';

@Component({
  moduleId: module.id,
  selector: 'qtty-cell-renderer',
  templateUrl: 'qtty-cell-renderer.component.html',
  styleUrls: ['qtty-cell-renderer.component.scss'],
})
export class QttyCellRendererComponent implements ICellRendererAngularComp {
  quantity: number;
  private rowData: any;

  constructor(private proNumberService: ProInventoryService) {}

  agInit(params: any): void {
    this.quantity = params.value;
    this.rowData = params.data;
  }

  sendInfo(): void {
    this.proNumberService.quantityDetails.next(this.rowData);
  }

  /* istanbul ignore next */
  refresh(): boolean {
    return false;
  }
}
