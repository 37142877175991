import { Component, OnInit, forwardRef, Input, HostListener } from '@angular/core';
import moment, { Moment } from 'moment';
import { DateRangeService } from '../../../core/services/date-range.service';
import { UnsubscribeComponent } from '../unsubscribe/unsubscribe.component';
import { takeUntil, skip } from 'rxjs/operators';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  AbstractControl,
  ControlContainer,
} from '@angular/forms';
import { DateRange } from '../../models/date-range';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangePickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateRangePickerComponent),
      multi: true,
    },
  ],
  host: {
    'window:keyup.escape': 'closeCalendar()',
  },
})
export class DateRangePickerComponent extends UnsubscribeComponent implements OnInit, ControlValueAccessor {
  @Input() formControlName: string;
  @Input() maxDate: Moment;

  startDate: Moment;
  endDate: Moment;
  calendarOpened: boolean;
  viewStartDate: string;
  viewEndDate: string;
  control: AbstractControl;
  onTouched: Function;

  onChange: any = (_) => {};

  constructor(private rangeService: DateRangeService, private controlContainer: ControlContainer) {
    super();
  }

  ngOnInit() {
    this.control = this.controlContainer.control.get(this.formControlName);
    this.initializeDates();
    this.rangeService.setInitialDates(this.startDate, this.endDate);

    this.rangeService
      .selectedDate()
      .pipe(
        takeUntil(this.destroyed$),
        skip(1)
      )
      .subscribe((range) => {
        this.viewStartDate = range.startDate ? range.startDate.format('L') : 'MM/DD/YYYY';
        this.startDate = range.startDate;
        this.endDate = range.endDate;
        this.viewEndDate = range.endDate ? range.endDate.format('L') : 'MM/DD/YYYY';
        this.onChange(range);
      });
  }

  writeValue(value: DateRange | string): void {
    /* istanbul ignore else */
    if (value) {
      const formValues = this.getDatesFromFormValue(value);
      this.startDate = moment(formValues.startDate);
      this.endDate = moment(formValues.endDate);
      this.rangeService.setInitialDates(this.startDate, this.endDate);
      this.viewStartDate = this.startDate && this.startDate.isValid() ? this.startDate.format('L') : 'MM/DD/YYYY';
      this.viewEndDate = this.endDate && this.endDate.isValid() ? this.endDate.format('L') : 'MM/DD/YYYY';
    }
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): null | { [key: string]: boolean } {
    switch (typeof control.value) {
      case 'string':
        const [startDate, endDate] = control.value.split('-');
        if (startDate && endDate) {
          const newStartDate = moment(startDate.trim(), 'L', true);
          const newEndDate = moment(endDate.trim(), 'L', true);

          if (newStartDate.isValid() && newEndDate.isValid() && newStartDate.isSameOrBefore(newEndDate, 'day')) {
            control.patchValue({ startDate: newStartDate, endDate: newEndDate });
            this.rangeService.setInitialDates(newStartDate, newEndDate);
            return null;
          } else {
            return { invalidRange: true };
          }
        } else {
          return { invalidRange: true };
        }
      default:
        const isValidRange = control.value.startDate.isSameOrBefore(control.value.endDate, 'day');
        return isValidRange ? null : { invalidRange: true };
    }
  }

  triggerCalendar() {
    this.initializeDates();
    this.calendarOpened = true;
  }

  @HostListener('window:keyup.escape')
  closeCalendar() {
    if (this.calendarOpened) {
      this.calendarOpened = false;
    }
  }

  getEndDate(): Moment {
    return this.startDate.clone().add(1, 'month');
  }

  onKeyUp(event: KeyboardEvent) {
    const { value } = event.target as HTMLInputElement;
    const dates = value.split(' - ');
    const startDate = moment(dates[0], 'L', true);

    /* istanbul ignore else */
    if (startDate.isValid() && event.key !== 'Backspace' && dates.length === 1) {
      (<HTMLInputElement>event.target).value = startDate.format('L') + ' - ';
    }
  }

  onKeyDown(event: KeyboardEvent) {
    this.onTouched();
    const { value } = event.target as HTMLInputElement;
    const dates = value.split(' - ');

    /* istanbul ignore else */
    if (dates.length === 2 && !dates[1] && event.key === 'Backspace') {
      event.preventDefault();
      (<HTMLInputElement>event.target).value = dates[0];
    }
  }

  private initializeDates() {
    if (this.startDate) {
      this.viewStartDate = this.startDate.format('L');
    } else {
      this.startDate = moment().subtract(1, 'weeks');
      this.viewStartDate = this.startDate.format('L');
    }

    if (this.endDate) {
      this.viewEndDate = this.endDate.format('L');
    } else {
      this.endDate = this.startDate.clone().add(1, 'weeks');
      this.viewEndDate = this.endDate.format('L');
    }
    this.maxDate = this.maxDate || moment();
  }

  private getDatesFromFormValue(value: string | DateRange): DateRange {
    switch (typeof value) {
      case 'string':
        const [startDate, endDate] = [...value.split('-')].map((v) => moment(v));
        return { startDate, endDate };
      default:
        return value;
    }
  }
}
