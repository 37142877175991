import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AppRoutes } from '../../enums/app-routes.enum';
import { NavigationExtras, Router } from '@angular/router';
import { CacheHelperService } from '../../../core/services/cache-helper.service';
import { DashboardType } from '../../enums/dashboard-type.enum';

@Component({
  moduleId: module.id,
  selector: 'link-cell-renderer',
  templateUrl: 'link-cell-renderer.component.html',
  styleUrls: ['link-cell-renderer.component.scss'],
})
export class LinkCellRendererComponent implements ICellRendererAngularComp {
  value: any;
  locationPath: string;
  url: string;
  type: string;
  queryParams: any;
  isSic: boolean = false;

  constructor(private router: Router, private cacheHelper: CacheHelperService) {
    this.locationPath = AppRoutes.PROS_DASHBOARD;
  }

  agInit(params: any): void {
    this.queryParams = params.queryParams;
    this.value = params.value;
    this.url = params.url || '';
    this.isSic = params.isSic ? params.isSic : this.isSic;
  }

  /* istanbul ignore next */
  refresh(): boolean {
    return false;
  }

  navigate(): void {
    const currentTab = this.cacheHelper.getCurrentSelectedTab(DashboardType.Request);
    this.cacheHelper.recordTab(DashboardType.Inventory, currentTab ? currentTab.tab : 'Printed PRO Labels');
    this.router.navigate([this.url], { queryParams: this.queryParams });
  }
}
