import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GridOptions } from 'ag-grid-community';
import { LabelTypeCellRendererComponent } from '../../shared/cell-renderer/label-type-cell-renderer/label-type-cell-renderer.component';
import { ProPadTypeCd } from '@xpo-ltl/sdk-common';

@Component({
  moduleId: module.id,
  selector: 'qtty-details-dialog',
  templateUrl: 'qtty-details-dialog.component.html',
  styleUrls: ['qtty-details-dialog.component.scss'],
})
export class QttyDetailsDialogComponent implements OnInit {
  gridOptions: GridOptions;
  gridCellStyle = {
    'border-left': '1px solid #D8D8D8',
    'border-bottom': '1px solid #D8D8D8',
    padding: '0 8px',
    'font-size': '14px',
  };

  constructor(public dialogRef: MatDialogRef<QttyDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) private data) {}

  ngOnInit(): void {
    this.initGrid();
  }

  initGrid(): void {
    this.gridOptions = {
      rowData: this.data.rows,
      suppressHorizontalScroll: true,
      suppressCellSelection: true,
      suppressRowHoverHighlight: true,
      suppressRowClickSelection: true,
      rowStyle: { border: 'none' },
      rowClass: 'custom-highlight',
      domLayout: 'autoHeight',
      onGridReady: (params) => {
        switch (this.data.rows[0].labelPadTypeCd) {
          case ProPadTypeCd.US_BLUE:
          case ProPadTypeCd.CA_BLUE:
            params.columnApi.setColumnsVisible(['rangeFrom', 'rangeTo', 'madCd'], true);
            break;
          case ProPadTypeCd.YELLOW:
            params.columnApi.setColumnsVisible(['rangeFrom', 'rangeTo', 'name'], true);
            break;
          default:
            params.columnApi.setColumnsVisible(
              [
                'blueRange.startingSerialNumber',
                'blueRange.endingSerialNumber',
                'yellowRange.endingSerialNumber',
                'yellowRange.startingSerialNumber',
                'name',
              ],
              true
            );
        }
        params.api.sizeColumnsToFit();
      },
      columnDefs: [
        {
          valueGetter: (params) => {
            return params.node.rowIndex + 1;
          },
          width: 30,
          minWidth: 30,
          cellStyle: {
            padding: 0,
            'background-color': '#F6F6F6',
            'justify-content': 'center',
            'font-weight': 700,
            cellStyle: this.gridCellStyle,
            editable: false,
            sortable: false,
            headerClass: 'dark-grey-header',
          },
        },
        {
          headerName: 'Label Type',
          field: 'labelPadTypeCd',
          minWidth: 140,
          cellStyle: this.gridCellStyle,
          editable: false,
          sortable: false,
          cellRendererFramework: LabelTypeCellRendererComponent,
        },
        {
          headerName: 'Quantity',
          field: 'quantity',
          minWidth: 120,
          cellStyle: this.gridCellStyle,
          editable: false,
          sortable: false,
        },
        {
          headerName: 'Range (from)',
          field: 'rangeFrom',
          cellStyle: this.gridCellStyle,
          minWidth: 120,
          valueGetter: (params) => {
            switch (params.data.labelPadTypeCd) {
              case ProPadTypeCd.US_BLUE:
              case ProPadTypeCd.CA_BLUE:
                return params.data.blueRange ? params.data.blueRange.startingSerialNumber : null;
              case ProPadTypeCd.YELLOW:
                return params.data.yellowRange ? params.data.yellowRange.startingSerialNumber : null;
            }
          },
          hide: true,
          editable: false,
          sortable: false,
        },
        {
          headerName: 'Range (to)',
          field: 'rangeTo',
          cellStyle: this.gridCellStyle,
          minWidth: 120,
          valueGetter: (params) => {
            switch (params.data.labelPadTypeCd) {
              case ProPadTypeCd.US_BLUE:
              case ProPadTypeCd.CA_BLUE:
                return params.data.blueRange ? params.data.blueRange.endingSerialNumber : null;
              case ProPadTypeCd.YELLOW:
                return params.data.yellowRange ? params.data.yellowRange.endingSerialNumber : null;
            }
          },
          hide: true,
          editable: false,
          sortable: false,
        },
        {
          headerName: 'Blue Range (from)',
          field: 'blueRange.startingSerialNumber',
          minWidth: 120,
          cellStyle: this.gridCellStyle,
          hide: true,
          editable: false,
          sortable: false,
        },
        {
          headerName: 'Blue Range (to)',
          field: 'blueRange.endingSerialNumber',
          minWidth: 120,
          cellStyle: this.gridCellStyle,
          hide: true,
          editable: false,
          sortable: false,
        },
        {
          headerName: 'Yellow Range (from)',
          field: 'yellowRange.startingSerialNumber',
          minWidth: 120,
          cellStyle: this.gridCellStyle,
          hide: true,
          editable: false,
          sortable: false,
        },
        {
          headerName: 'Yellow Range (to)',
          field: 'yellowRange.endingSerialNumber',
          minWidth: 120,
          cellStyle: this.gridCellStyle,
          hide: true,
          editable: false,
          sortable: false,
        },
        {
          headerName: 'MADCode',
          field: 'madCd',
          minWidth: 120,
          cellStyle: this.gridCellStyle,
          hide: true,
          editable: false,
          sortable: false,
        },
        {
          headerName: 'Name',
          field: 'name',
          minWidth: 120,
          cellStyle: this.gridCellStyle,
          valueGetter: (params) => {
            if (!params.data.driverEmployeeDtl) {
              return null;
            }
            return `${params.data.driverEmployeeDtl.firstName} ${params.data.driverEmployeeDtl.lastName}`;
          },
          hide: true,
          editable: false,
          sortable: false,
        },
      ],
    } as GridOptions;
  }

  scroll(e: Event): void {
    e.preventDefault();
  }

  close(): void {
    this.dialogRef.close();
  }
}
