import { Injectable } from '@angular/core';
import {
  HumanResourceApiService,
  ListEmployeesByRoleIdPath,
  ListEmployeesByRoleIdResp,
  GetEmployeeDetailsByEmpIdResp,
  GetEmployeeDetailsByEmpIdPath,
} from '@xpo-ltl/sdk-humanresource';
import { map, refCount, publishReplay, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmployeeInfoService {
  private employees$: Observable<Array<{ id; name }>>;

  constructor(public humanResourceApiService: HumanResourceApiService) {}

  getEmployeeName(empId: string): Observable<GetEmployeeDetailsByEmpIdResp> {
    const pathParams = new GetEmployeeDetailsByEmpIdPath();
    pathParams.employeeId = empId;
    return this.humanResourceApiService
      .getEmployeeDetailsByEmpId(pathParams, null, { loadingOverlayEnabled: false })
      .pipe(take(1));
  }

  getEmployeesByRoleId(roleId: string): Observable<{ id; name }[]> {
    if (!this.employees$) {
      const pathParams = new ListEmployeesByRoleIdPath();
      pathParams.roleId = roleId;
      this.employees$ = this.humanResourceApiService.listEmployeesByRoleId(pathParams).pipe(
        map((resp: ListEmployeesByRoleIdResp) => {
          return resp.employeeNames.map((emp) => {
            return { id: emp.employeeId, name: emp.fullName };
          });
        }),
        publishReplay(1),
        refCount()
      );
    }

    return this.employees$;
  }

  clearCache() {
    this.employees$ = null;
  }
}
