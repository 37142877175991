import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { RowNode } from 'ag-grid-community';

@Injectable({
  providedIn: 'root',
})
export class ErrorStateManagerService {
  private errorSubject = new BehaviorSubject<boolean>(false);
  private nodesWithError = new Map<string, RowNode>();

  constructor() {}

  setError(node: RowNode, key?: string): void {
    this.nodesWithError.set(key || node.id, node);
    this.errorSubject.next(this.nodesWithError.size > 0);
  }

  errorStates(): Observable<boolean> {
    return this.errorSubject;
  }

  hasError(param: RowNode | string) {
    let key;

    if (typeof param === 'string') {
      key = param;
    } else {
      key = param.id;
    }
    return this.nodesWithError.has(key);
  }

  cleanErrorState(param: RowNode | string) {
    let key;

    if (typeof param === 'string') {
      key = param;
    } else {
      key = param.id;
    }

    if (this.nodesWithError.has(key)) {
      this.nodesWithError.delete(key);
    }

    this.errorSubject.next(this.nodesWithError.size > 0);
  }

  clearStates(): void {
    this.nodesWithError.clear();
    this.errorSubject.next(false);
  }
}
