import { NgModule } from '@angular/core';
import { XpoDialogModule } from '@xpo/ngx-core';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { SharedVendorModule } from '../shared-vendor.module';
import { ConfirmRequestDialogComponent } from './confirm-request-dialog/confirm-request-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { QttyDetailsDialogComponent } from './qtty-details-dialog/qtty-details-dialog.component';
import { AgGridModule } from 'ag-grid-angular';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ltl-grid';
import { BrowserGuardDialogComponent } from './browser-guard-dialog/browser-guard-dialog.component';

const dialogs = [
  // YOUR DIALOGS
  ConfirmRequestDialogComponent,
  NotAuthorizedComponent,
  QttyDetailsDialogComponent,
  BrowserGuardDialogComponent,
];

@NgModule({
  declarations: [...dialogs],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedVendorModule,
    XpoDialogModule,
    XpoAgGridModule,
    AgGridModule,
    XpoCardModule,
  ],
  exports: [...dialogs],
  entryComponents: [...dialogs],
})
export class DialogModule {}
