import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { conformToMask } from 'angular2-text-mask';

@Component({
  selector: 'app-tooltip-renderer',
  templateUrl: './tooltip-renderer.component.html',
  styleUrls: ['./tooltip-renderer.component.scss'],
})
export class TooltipRendererComponent implements ICellRendererAngularComp {
  message: string;
  value: string;
  isCurrency: boolean;
  classes: any;

  private params;

  constructor() {}

  /* istanbul ignore next */
  refresh(): boolean {
    return false;
  }

  agInit(params) {
    this.params = params;
    this.message = params.message;
    this.isCurrency = params.isCurrency;
    this.classes = {
      'align-left': params.alignLeft,
      currency: params.isCurrency && params.value,
    };

    if (this.params.isCurrency && this.params.value) {
      this.value = conformToMask(
        params.value.toString(),
        createNumberMask({ prefix: '', allowDecimal: true }),
        {}
      ).conformedValue;
    } else {
      this.value = params.value;
    }
  }
}
