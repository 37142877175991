import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import moment, { Moment } from 'moment';
import { WeekDays } from '../../enums/week-days.enum';
import { DateRangeService } from '../../../core/services/date-range.service';
import { skip, takeUntil } from 'rxjs/operators';
import { UnsubscribeComponent } from '../unsubscribe/unsubscribe.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent extends UnsubscribeComponent implements OnInit {
  @Input() initialDate: Moment;
  @Input() isEndDate: boolean;
  @Input() maxDate: Moment;

  currentMonth: Moment[][] = [];
  currentNumberOfWeeks: number;
  viewDate: string;
  months: Moment[] = [];
  selectingMonth: boolean;
  selectingYear: boolean;
  years: Moment[] = [];

  private currentDate: Moment;
  private lastYear: number;

  constructor(private rangeService: DateRangeService) {
    super();
  }

  ngOnInit() {
    this.currentDate = moment(this.initialDate);
    this.setMonthWeeks(this.currentDate);
    this.rangeService
      .direction()
      .pipe(
        takeUntil(this.destroyed$),
        skip(1)
      )
      .subscribe((dir) => {
        if (typeof dir === 'string') {
          this.redrawCalendar(dir);
        } else {
          const nextDate = dir.clone();
          if (this.isEndDate) {
            nextDate.add(1, 'month');
          }
          this.currentDate = nextDate;
          this.setMonthWeeks(this.currentDate);
        }
      });

    this.rangeService
      .selectedDate()
      .pipe(
        takeUntil(this.destroyed$),
        skip(1)
      )
      .subscribe((range) => {
        this.setMonthWeeks(this.currentDate);
      });
  }

  arrowClick(dir: 'prev' | 'next') {
    if (this.selectingYear) {
      this.setYears(dir === 'prev' ? this.lastYear - 30 : this.lastYear, dir);
    } else if (this.selectingMonth) {
      const action = dir === 'prev' ? 'subtract' : 'add';
      const nextYear: Moment = this.currentDate[action](1, 'years');
      this.viewDate = nextYear.format('YYYY');
      this.setMonths(nextYear);
    } else {
      this.rangeService.nextDirection(dir);
    }
  }

  isToday(day: Moment): boolean {
    return day && moment().isSame(day, 'day');
  }

  dayClicked(day: Moment) {
    this.rangeService.nextDate(day);
  }

  monthClicked(month: Moment) {
    this.selectingMonth = false;
    this.rangeService.nextDirection(month);
  }

  yearClicked(year: Moment) {
    this.selectingMonth = false;
    this.selectingYear = false;

    this.rangeService.nextDirection(year);
  }

  selectMonthOrYear() {
    if (!this.selectingMonth) {
      this.setMonths(this.currentDate.clone());
      this.selectingMonth = true;
    } else {
      this.setYears(this.currentDate.year());
      this.selectingYear = true;
    }
    this.viewDate = this.currentDate.format('YYYY');
  }

  isCurrentMonth(month: Moment): boolean {
    return moment().isSame(month, 'month');
  }

  isMonthDisabled(month: Moment): boolean {
    return moment().isBefore(month, 'month');
  }

  isYearDisabled(year: Moment): boolean {
    return moment().isBefore(year, 'year');
  }

  isRightArrowDisabled(): boolean {
    if (this.selectingYear) {
      return moment().year() <= this.years[this.years.length - 1].year();
    } else if (this.selectingMonth) {
      return moment().year() <= this.months[this.months.length - 1].year();
    }
    return this.currentDate.isSameOrAfter(this.maxDate, 'month');
  }

  isDaySelected(day: Moment) {
    return day && this.rangeService.isDaySelected(day);
  }

  isBetweenCurrentRange(day: Moment): boolean {
    return day && this.rangeService.isInRange(day);
  }

  isFirstDayOfMonth(day: Moment): boolean {
    return this.currentDate
      .clone()
      .startOf('month')
      .isSame(day, 'day');
  }

  isLastDayOfMonth(day: Moment): boolean {
    return this.currentDate
      .clone()
      .endOf('month')
      .isSame(day, 'day');
  }

  isSelectedStartDate(day: Moment): boolean {
    return (
      day &&
      this.rangeService.allDatesSelected() &&
      this.rangeService.isDaySelected(day) &&
      this.rangeService
        .getRange()
        .startDate.clone()
        .isSame(day, 'day')
    );
  }

  isSelectedEndDate(day: Moment): boolean {
    const endDate = this.rangeService.getRange().endDate;
    return (
      day &&
      this.rangeService.allDatesSelected() &&
      this.rangeService.isDaySelected(day) &&
      (endDate && endDate.clone().isSame(day, 'day'))
    );
  }

  private redrawCalendar(dir) {
    if (dir === 'next') {
      this.currentDate = moment(this.currentDate).add(1, 'month');
    } else {
      this.currentDate = moment(this.currentDate).subtract(1, 'month');
    }

    this.setMonthWeeks(this.currentDate);
  }

  private setMonthWeeks(date?: Moment) {
    this.viewDate = moment(date).format('MMMM YYYY');
    this.currentNumberOfWeeks = Math.abs(
      moment
        .duration(
          moment(date)
            .startOf('month')
            .diff(moment(date).endOf('month'))
        )
        .weeks()
    );
    const currentMonthNumber = moment(date).month();
    const nextWeek = moment(date).startOf('month');
    const firstDayIndex = this.getDayIndex(nextWeek.format('ddd'));

    for (let i = 0; i <= this.currentNumberOfWeeks; i++) {
      if (i) {
        nextWeek.add(1, 'weeks');
      }
      const weekDays = [];

      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < firstDayIndex) {
          weekDays[j] = '';
        } else if (nextWeek.weekday(j).month() === currentMonthNumber) {
          weekDays[j] = moment(nextWeek.weekday(j)).startOf('day');
        } else {
          weekDays[j] = '';
        }
      }

      this.currentMonth[i] = weekDays;
    }
  }

  private setMonths(year?: Moment) {
    const yearMonths = moment(year).startOf('year');
    const newMonths: Moment[] = [];

    for (let i = 0; i < 12; i++) {
      newMonths.push(
        yearMonths
          .month(i)
          .clone()
          .startOf('month')
      );
    }

    this.months = newMonths;
  }

  private setYears(initialYear: number, dir?: 'prev' | 'next') {
    const nextYear = this.currentDate.clone();
    const newYears: Moment[] = [];
    const action = dir === 'next' ? 'add' : 'subtract';

    if (initialYear) {
      nextYear.set('year', initialYear);
    }

    for (let i = 0; i < 30; i++) {
      newYears.push(nextYear[action](i === 0 ? 0 : 1, 'year').clone());
    }

    this.years = dir === 'next' ? newYears : newYears.reverse();
    this.lastYear = this.years[this.years.length - 1].year();
  }

  private getDayIndex(day: string): number {
    /* istanbul ignore next */
    switch (day) {
      case WeekDays.Mon:
        return 1;
      case WeekDays.Tue:
        return 2;
      case WeekDays.Wed:
        return 3;
      case WeekDays.Thu:
        return 4;
      case WeekDays.Fri:
        return 5;
      case WeekDays.Sat:
        return 6;
      case WeekDays.Sun:
        return 0;
      default:
        return -1;
    }
  }
}
