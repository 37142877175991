export enum DashboardType {
  RequestPrinted = 'REQUEST_PRINTED',
  RequestElectronic = 'REQUEST_ELECTRONIC',
  InventoryPrinted = 'INVENTORY_PRINTED',
  InventoryElectronic = 'INVENTORY_ELECTRONIC',
  VendorWarehouse = 'VENDOR_WAREHOUSE',
  Request = 'REQUEST',
  Inventory = 'INVENTORY',
  Vendor = 'VENDOR',
  VendorOrders = 'VENDOR_ORDERS',
}
