import { AfterViewChecked, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ChartMetrics } from './models/chart-metrics';
import * as Highcharts from 'highcharts';
import { conformToMask } from 'angular2-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

@Component({
  moduleId: module.id,
  selector: 'app-label-chart-metrics',
  templateUrl: 'label-chart-metrics.component.html',
  styleUrls: ['label-chart-metrics.component.scss'],
})
export class LabelChartMetricsComponent implements OnInit, OnChanges, OnDestroy, AfterViewChecked {
  @Input() chartMetrics: ChartMetrics;
  @Input() height: String = '152';
  columnOptions: any;
  globalOptions: Highcharts.Options;
  tooltip: HTMLElement;
  seriesPoints: NodeList;

  constructor() {
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  ngOnInit(): void {
    this.globalOptions = {
      lang: {
        thousandsSep: '',
      },
    };
  }

  ngOnChanges() {
    if (this.chartMetrics) {
      this.transFormZeroToNull();
      this.columnOptions = {
        chart: { type: 'column', height: this.height, spacingTop: 25, spacingLeft: 15, spacingRight: 15 },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            pointWidth: this.chartMetrics.width || null,
            borderWidth: 0,
            point: {
              events: {
                click: () => {
                  return false;
                },
              },
            },
            minPointLength: 2,
            dataLabels: {
              enabled: false,
            },
          },
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
              hover: {
                enabled: true,
              },
              stickyTracking: false,
            },
            events: {
              legendItemClick: () => {
                return false;
              },
            },
          },
        },
        legend: {
          enabled: this.chartMetrics.hideLegend ? false : true,
          verticalAlign: 'bottom',
          align: 'center',
          x: 0,
          y: 0,
          itemStyle: {
            cursor: 'default',
          },
        },
        tooltip: {
          enabled: false,
        },
        title: { text: this.chartMetrics.title, align: 'center' },
        xAxis: {
          categories: this.chartMetrics.categories,
          labels: {
            style: {
              fontSize: '12px',
              color: 'black',
              fontWeight: '700',
            },
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: null,
          },
          labels: {
            style: {
              fontSize: '12px',
              color: 'black',
              fontWeight: '700',
            },
          },
        },
        series: this.chartMetrics.series,
      };
    }
  }

  ngAfterViewChecked(): void {
    this.seriesPoints = document.querySelectorAll('rect.highcharts-point');

    if (this.seriesPoints) {
      this.seriesPoints.forEach((p) => p.addEventListener('mouseover', this.onMouseOver));
      this.seriesPoints.forEach((p) => p.addEventListener('mouseleave', this.onMouseLeave));
    }
  }

  ngOnDestroy() {
    if (this.seriesPoints) {
      this.seriesPoints.forEach((p) => p.removeEventListener('mouseover', this.onMouseOver));
      this.seriesPoints.forEach((p) => p.removeEventListener('mouseleave', this.onMouseLeave));
    }
  }

  onMouseOver(e: MouseEvent) {
    e.stopImmediatePropagation();
    const bar = document.querySelector('rect.highcharts-point:hover') as any;
    const checkoutInfo = this.getCheckoutInfo(bar);
    if (bar) {
      // Manually creating, positioning and appending tooltip div for metrics bar
      this.tooltip = this.tooltip || document.createElement('div');
      this.tooltip.classList.add('metrics-tooltip');
      this.tooltip.innerHTML = `<div class="metrics-tooltip" style="color:#fff; background-color: #000; padding: 8px;">${
        checkoutInfo
          ? conformToMask((bar.point.y - +checkoutInfo).toString(), createNumberMask({ prefix: '' }), {}).conformedValue
          : conformToMask((<any>event.target).point.y.toString(), createNumberMask({ prefix: '' }), {}).conformedValue
      }<div class="down-arrow"></div></div>`;
      const position: any = bar.getBoundingClientRect();
      // Needs to append hidden to get width and height of element
      this.tooltip.style.visibility = 'hidden';
      const chartContainer = bar.closest('.highcharts-container');
      this.tooltip.style.position = 'fixed';
      chartContainer.appendChild(this.tooltip);
      // Tooltip X and Y offset calculations and positions
      this.tooltip.style.top = position.y - this.tooltip.offsetHeight - 6 + 'px';
      const xOffset = position.width - this.tooltip.offsetWidth;
      this.tooltip.style.left = position.x + xOffset / 2 + 'px';
      this.tooltip.style.visibility = 'visible';
    }
  }

  private getCheckoutInfo(bar: any): string | null {
    if (!this.chartMetrics.checkoutInfo || !this.chartMetrics.checkoutInfo[bar.point.index]) {
      return null;
    }
    const assigned = this.chartMetrics.checkoutInfo[bar.point.index];
    const checkedOut = bar.point.y - assigned;
    return checkedOut > 0 ? checkedOut.toString() : null;
  }

  onMouseLeave(e: MouseEvent) {
    e.stopImmediatePropagation();
    if (this.tooltip) {
      this.tooltip.style.visibility = 'hidden';
    }
  }

  private transFormZeroToNull() {
    if (this.chartMetrics.series.length) {
      this.chartMetrics.series.forEach((series) => {
        if (series.data) {
          series.data = series.data.map((value) => (value === 0 ? null : value));
        }
      });
    }
  }
}
