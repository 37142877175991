import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { CellClassParams, GridApi } from 'ag-grid-community';

@Component({
  selector: 'app-group-cell-renderer',
  templateUrl: './group-cell-renderer.component.html',
  styleUrls: ['./group-cell-renderer.component.scss'],
})
export class GroupCellRendererComponent implements ICellRendererAngularComp {
  params: CellClassParams;
  icon: 'add' | 'remove';

  private api: GridApi;
  private expanded: boolean = false;

  constructor() {}

  agInit(params: CellClassParams) {
    this.api = params.api;
    this.params = params;
    this.icon = this.params.node.expanded ? 'remove' : 'add';
    this.expanded = this.params.node.expanded;
  }

  /* istanbul ignore next */
  refresh(): boolean {
    return true;
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
    this.icon = this.expanded ? 'remove' : 'add';
    this.api.setRowNodeExpanded(this.params.node, !this.params.node.expanded);
  }
}
