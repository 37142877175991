import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoShellRoute } from '@xpo/ngx-core';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';
import { UserService } from './core/services/user.service';
import { UnsubscribeComponent } from './shared/components/unsubscribe/unsubscribe.component';
import { UserRole } from './shared/enums/user-role/user-role.enum';
import { NotAuthorizedComponent } from './dialogs/not-authorized/not-authorized.component';
import { LocationService } from './core/services/location.service';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent extends UnsubscribeComponent {
  constructor(
    private configManagerService: ConfigManagerService,
    private dialog: MatDialog,
    private userService: UserService,
    private authService: XpoLtlAuthenticationService,
    private locationService: LocationService
  ) {
    super();
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService
      .initAuthSetup$(region)
      .pipe(take(1))
      .subscribe();
    this.title = configManagerService.getSetting<string>(ConfigManagerProperties.appName);
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    this.userService.getLoggedInUser$().subscribe(
      (user) => {
        if (!this.userService.isAuthorizedUser(user)) {
          this.userService.setRole(UserRole.User);
        }
        this.userRole = this.userService.getRole();
        this.setRoutes();
        if (this.userRole === UserRole.User) {
          this.locationService.loadCustomAssignmentLocation(user.sicCode);
        }
      },
      (error) => {
        this.userService.user = undefined;
        this.showNotAuthorizedDialog();
      }
    );
  }

  title: string;
  build: string;
  routes: XpoShellRoute[];
  userRole: string;

  private showNotAuthorizedDialog() {
    this.dialog.open(NotAuthorizedComponent, { disableClose: true });
  }

  setRoutes() {
    switch (this.userRole) {
      case UserRole.Admin:
        this.routes = [
          {
            label: 'Requests',
            path: `/dashboards/requests-dashboard`,
          },
          {
            label: 'Inventory',
            path: `/dashboards/inventory-dashboard`,
          },
          {
            label: 'Vendor',
            path: `/dashboards/vendor-dashboard`,
          },
        ];
        break;
      case UserRole.Manager:
      case UserRole.Sales:
      case UserRole.User:
        this.routes = [
          {
            label: 'Requests',
            path: `/dashboards/requests-dashboard`,
          },
          {
            label: 'Inventory',
            path: `/dashboards/inventory-dashboard`,
          },
        ];
        break;
      default:
        this.routes = [
          {
            label: 'Requests',
            path: `/dashboards/requests-dashboard`,
          },
        ];
        break;
    }
  }
}
