import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'app-metrics-projection',
  templateUrl: 'metrics-projection.component.html',
  styleUrls: ['metrics-projection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetricsProjectionComponent implements OnChanges {
  @Input() timeType: string = 'days';
  @Input() summary: any;

  projections: string[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.summary && changes.summary.currentValue) {
      this.getProjections(this.summary);
    }
  }

  private getProjections(summary: any[]): void {
    this.projections = [];
    const order: string[] = [];
    const forecastType = this.timeType === 'days' ? 'ForecastDays' : 'ForecastWeeks';
    summary.forEach((s) => {
      order.push(s.summaryFor);
    });
    summary = _.sortBy(summary, (obj) => {
      return _.indexOf(order, obj.summaryFor);
    });
    summary
      .filter((s) => s.summarySubject === forecastType)
      .map((data) => {
        if (!data.useCount) {
          this.projections.push('Estimate Unavailable');
        } else {
          this.projections.push(`Estimated to last ${data.useCount} ${this.timeType}`);
        }
      });

    this.cdr.detectChanges();
  }
}
