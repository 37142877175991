import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { EmployeeInfoService } from '../../../core/services/employee-info.service';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { Observable } from 'rxjs';
import { map, takeUntil, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { UnsubscribeComponent } from '../../components/unsubscribe/unsubscribe.component';
import { MatAutocompleteTrigger } from '@angular/material';

@Component({
  moduleId: module.id,
  selector: 'assigned-cell-renderer',
  templateUrl: 'assigned-cell-renderer.component.html',
  styleUrls: ['assigned-cell-renderer.component.scss'],
})
export class AssignedCellRendererComponent extends UnsubscribeComponent implements ICellRendererAngularComp {
  selected: any = null;
  users: any[];
  isGroup: false;
  params: any;
  isNewOrInProcess = false;
  selectedUserName: string;
  filteredUsers$: Observable<any>;
  isEnabled = false;
  isEditable = false;
  name: FormControl;
  gridBody: HTMLElement;
  @ViewChild('trigger', { static: false }) trigger: MatAutocompleteTrigger;
  @ViewChild('input', { static: false }) input: ElementRef;

  constructor(private employeeInfoService: EmployeeInfoService, private configManagerService: ConfigManagerService) {
    super();
    this.closePanel = this.closePanel.bind(this);
  }

  agInit(params: any): void {
    const roleId = this.configManagerService.getSetting<string>(ConfigManagerProperties.ltlProSystemAdminId);
    this.employeeInfoService.getEmployeesByRoleId(roleId).subscribe((users) => {
      this.users = users;
      this.isNewOrInProcess = params.isNewOrInProcess;
      this.isEnabled = params.isEnabled;
      this.isEditable = params.isEditable;
      this.params = params;
      this.selected = params.value;
      /* istanbul ignore else */
      if (params.value) {
        this.selectedUserName = this.users.find((user) => user.id === params.value).name;
      }
      this.name = new FormControl(this.params.value || '');

      if (this.isEditable) {
        this.filteredUsers$ = this.name.valueChanges.pipe(
          takeUntil(this.destroyed$),
          startWith(''),
          map((x) => {
            const value = x ? x.toLowerCase() : '';
            return this.users.filter((u) => {
              return u.name.toLowerCase().includes(value);
            });
          })
        );
      }
    });
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  change($event) {
    this.input.nativeElement.blur();
    /* istanbul ignore else */
    if (this.params.onSelectionChanged instanceof Function) {
      const params = {
        event: $event.option,
        rowData: this.params.node,
      };
      this.params.onSelectionChanged(params);
      this.filteredUsers$ = this.name.valueChanges.pipe(
        takeUntil(this.destroyed$),
        startWith(''),
        map((x) => {
          const value = x ? x.toLowerCase() : '';
          return this.users.filter((u) => {
            return u.name.toLowerCase().includes(value);
          });
        })
      );
    }
  }

  displayWithName(value) {
    if (value) {
      return this.users.find((x) => x.id === value).name;
    } else {
      return '';
    }
  }

  onOpened() {
    // need to get gridbody to list to scroll event
    this.gridBody =
      document.querySelector('div.ag-body-viewport.ag-layout-normal.ag-row-animation') ||
      document.querySelector('div.ag-body-viewport.ag-layout-normal.ag-row-no-animation');
    this.gridBody.addEventListener('scroll', this.closePanel);
  }

  closePanel() {
    this.input.nativeElement.blur();
    this.trigger.closePanel();
    this.gridBody.removeEventListener('scroll', this.closePanel);
  }

  handleArrowClick(event: MouseEvent) {
    event.stopPropagation();
    if (this.trigger.autocomplete.isOpen) {
      this.trigger.closePanel();
    } else {
      this.trigger.openPanel();
    }
  }
}
