import { Component, OnInit, Input } from '@angular/core';
import { RequestStatus } from '../../enums/request-status.enum';

@Component({
  selector: 'app-request-status',
  templateUrl: './request-status.component.html',
  styleUrls: ['./request-status.component.scss'],
})
export class RequestStatusComponent implements OnInit {
  @Input() status: string;

  constructor() {}

  ngOnInit() {}

  getCssClassForStatus(status: RequestStatus) {
    switch (status) {
      case RequestStatus.Approve:
        return 'approve';
      case RequestStatus.Decline:
      case RequestStatus.Cancel:
        return 'decline';
      case RequestStatus.InProcess:
        return 'in-process';
      case RequestStatus.New:
        return 'new';
      default:
        return 'default';
    }
  }

  formatStatusText(status: RequestStatus) {
    return status.split(/(?=[A-Z])/g).join(' ');
  }
}
