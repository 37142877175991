import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import _moment from 'moment';
import { RefreshService } from '../../../core/services/refresh.service';
const moment = _moment;

@Component({
  selector: 'app-refresh-helper',
  templateUrl: './refresh-helper.component.html',
  styleUrls: ['./refresh-helper.component.scss'],
})
export class RefreshHelperComponent implements OnInit, OnDestroy {
  private dateInterval: any;

  lastUpdateDate: string;
  currentDate: string;

  constructor(private refreshService: RefreshService) {}

  ngOnInit() {
    this.lastUpdateDate = moment().format(`L [at] hh:mm A`);
    this.currentDate = moment().format('dddd, DD MMMM YYYY');

    this.dateInterval = this.registerDateInterval();
  }

  onClick() {
    this.lastUpdateDate = moment().format('L [at] hh:mm A');
    this.refreshService.notifyRefresh();
  }

  ngOnDestroy() {
    clearTimeout(this.dateInterval);
  }

  private timeUntilMidnight(): number {
    return moment()
      .add(1, 'days')
      .startOf('day')
      .diff(moment());
  }

  private registerDateInterval() {
    return setTimeout(() => {
      this.currentDate = moment().format('dddd, DD MMMM YYYY');

      this.dateInterval = this.registerDateInterval();
    }, this.timeUntilMidnight());
  }
}
