import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { LoginService } from '@xpo-ltl/login';
import { User } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, delay, retryWhen, take, tap } from 'rxjs/operators';
import { ConfigManagerProperties } from '../../shared/enums/config-manager-properties.enum';
import { UserRole } from '../../shared/enums/user-role/user-role.enum';
import UserRoleHelper from '../../shared/enums/user-role/user-role-helper';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly roleNames;
  private readonly isProduction;
  private userSubject = new BehaviorSubject<User>(undefined);

  get user() {
    return this.userSubject.value;
  }

  set user(u: User) {
    this.userSubject.next(u);
  }

  private loggedInSubject = new BehaviorSubject<boolean>(false);

  constructor(private loginService: LoginService, private configManagerService: ConfigManagerService) {
    this.isProduction = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
    this.roleNames = {
      [UserRole.User]: this.isProduction ? 'LTL_PRO_USERS' : 'TST_LTL_PRO_USERS',
      [UserRole.Manager]: this.isProduction ? 'LTL_PRO_CS_ADMIN' : 'TST_LTL_PRO_CS_ADMIN',
      [UserRole.Admin]: this.isProduction ? 'LTL_PRO_IT_ADMIN' : 'TST_LTL_PRO_IT_ADMIN',
      [UserRole.Sales]: this.isProduction ? 'LTL_PRO_SALES_SUPT' : 'TST_LTL_PRO_SALES_SUPT',
    };
  }

  getLoggedInUser$(): Observable<User> {
    const user$ = !!this.user
      ? of(this.user)
      : this.loginService
          .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
          .pipe(
            retryWhen((errors) =>
              errors.pipe(
                delay(1000),
                take(5)
              )
            ),
            catchError((error) => this.handleUserError(error, true))
          );

    return user$.pipe(
      tap((user: User) => {
        if (!!user) {
          this.user = user;
          this.user.sicCode = 'USE';
          this.loggedInSubject.next(true);
        }
      })
    );
  }

  /** TODO: an authorized user can have any set of roles defined above... */
  isAuthorizedUser(user: User): boolean {
    return (
      this.hasRole(user.roles, this.roleNames[UserRole.User]) ||
      this.hasRole(user.roles, this.roleNames[UserRole.Manager]) ||
      this.hasRole(user.roles, this.roleNames[UserRole.Admin]) ||
      this.hasRole(user.roles, this.roleNames[UserRole.Sales])
    );
  }

  hasRole(userRoles: string[], roleOf: string): boolean {
    if (!userRoles || !roleOf) {
      return false;
    }

    const roles = userRoles.map((role) => role.substring(role.lastIndexOf('/') + 1));
    return roles.includes(roleOf);
  }

  /** TODO: some authorized users may have mutliple roles... see role-check-guard.ts as it handles this!!! */
  hasMultipleRoles(user: User): boolean {
    return (
      this.hasRole(user.roles, this.roleNames[UserRole.User]) &&
      this.hasRole(user.roles, this.roleNames[UserRole.Admin])
    );
  }

  get isBasicUser(): boolean {
    return this.hasRole(this.user.roles, this.roleNames[UserRole.User]);
  }

  get isManagerUser(): boolean {
    return this.hasRole(this.user.roles, this.roleNames[UserRole.Manager]);
  }

  get isAdmin(): boolean {
    return this.hasRole(this.user.roles, this.roleNames[UserRole.Admin]);
  }

  get isSalesUSer(): boolean {
    return this.hasRole(this.user.roles, this.roleNames[UserRole.Sales]);
  }

  setRole(role: string) {
    if (!!this.user) {
      const validRole = UserRoleHelper.checkRole(role);
      if (!!validRole) {
        this.user.roles.length = 0;
        this.user.roles.push(this.roleNames[validRole]);
      }
    }
  }

  getRole(): string {
    if (this.user.roles && this.hasRole(this.user.roles, this.roleNames[UserRole.Admin])) {
      return UserRole.Admin;
    }
    if (this.user.roles && this.hasRole(this.user.roles, this.roleNames[UserRole.Manager])) {
      return UserRole.Manager;
    }
    if (this.user.roles && this.hasRole(this.user.roles, this.roleNames[UserRole.Sales])) {
      return UserRole.Sales;
    }
    return UserRole.User;
  }

  onLogoutUser() {
    this.loginService.clear();
    this.userSubject.next(undefined);
    this.loggedInSubject.complete();
    this.user = undefined;
  }

  private handleUserError(error: any, resetUser = false) {
    console.error(error);

    if (resetUser) {
      this.userSubject.next(undefined);
      this.loggedInSubject.complete();
    }

    return throwError(error);
  }
}
