import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Metrics } from './models/metrics';

@Component({
  moduleId: module.id,
  selector: 'app-label-metrics',
  templateUrl: 'label-metrics.component.html',
  styleUrls: ['label-metrics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelMetricsComponent {
  @Input() metrics: Metrics;
  @Input() isLoading = true;
}
