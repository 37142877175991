import { Component, OnInit } from '@angular/core';
import { ErrorStateManagerService } from '../../../core/services/error-state-manager.service';
import { UnsubscribeComponent } from '../unsubscribe/unsubscribe.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-hold-notification',
  templateUrl: './hold-notification.component.html',
  styleUrls: ['./hold-notification.component.scss'],
})
export class HoldNotificationComponent extends UnsubscribeComponent implements OnInit {
  hasError = false;

  constructor(private errorStateManager: ErrorStateManagerService) {
    super();
  }

  ngOnInit() {
    this.errorStateManager
      .errorStates()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((hasError) => {
        this.hasError = hasError;
      });
  }
}
