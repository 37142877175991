import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridAction } from '../../models/grid-action';
import { Actions } from '../../enums/actions.enum';
import { NoteType } from '../../enums/note-type.enum';

@Component({
  selector: 'grid-actions-renderer',
  templateUrl: './grid-actions-renderer.component.html',
  styleUrls: ['./grid-actions-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'lau-GridActions',
  },
})
export class GridActionsRendererComponent implements ICellRendererAngularComp {
  params: any;
  gridActions: GridAction[];
  hasNote: boolean = false;
  isParent: boolean;
  detailId: number;
  notes: number = 0;
  comments: number = 0;
  badgeLabel: number;

  constructor() {}

  /* istanbul ignore next */
  refresh(): boolean {
    return false;
  }

  agInit(params) {
    this.gridActions = params.customActions || null;
    if (params.customActions[0].actionType !== Actions.ORDER_NOTE) {
      this.checkHasNotes(params);
    }
  }

  callAction(action: Function, actionType: Actions, data?: any): void {
    action(data);
  }

  checkHasNotes(params) {
    this.isParent = params.node.group;
    this.hasNote = !this.isParent;
    const isNotes = this.gridActions && this.gridActions[0].actionType === Actions.NOTES;
    /* istanbul ignore else */
    if (!this.isParent) {
      const parentNodeData = params.node.parent.data;
      const notes = parentNodeData ? parentNodeData.assignmentRequestNote : params.data.assignmentRequestNote;
      this.detailId = params.node.data.proAssignRequestDetailSequenceNbr;
      /* istanbul ignore else */
      if (notes instanceof Array) {
        for (const note of notes) {
          if (note.proAssignRequestDetailSequenceNbr === this.detailId) {
            if (note.noteTypeCd === NoteType.AddNote) {
              this.notes++;
            } else if (note.noteTypeCd === NoteType.CommentNote) {
              this.comments++;
            }
          }
        }
      }
      this.hasNote = isNotes ? this.notes > 0 : this.comments > 0;
    }
  }

  getBadgeLabel(action) {
    this.badgeLabel = action.actionType === Actions.NOTES ? this.notes : this.comments;
    return this.badgeLabel;
  }

  getIcon(action: GridAction) {
    return action.actionType === Actions.NOTES
      ? this.notes > 0
        ? action.icon
        : action.isGuest
        ? action.icon
        : 'note_add'
      : action.icon;
  }
}
