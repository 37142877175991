import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { GridApi, IServerSideDatasource } from 'ag-grid-community';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.scss'],
})
export class CustomPaginationComponent implements OnInit, OnChanges {
  @Input('gridApi') api: GridApi;
  @Input() dataSource: IServerSideDatasource;

  currentPage = 1;
  totalPages = 1;
  numberOfRows = 1;
  defaultPageSize = 10;
  pageSizesOptions = [10, 25, 50, 100];
  pageSizeInfo = '';
  currentPageSize = 10;

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.api) {
      this.api.addEventListener('paginationChanged', () => {
        const page = this.api.paginationGetCurrentPage();
        this.currentPage = page + 1;
        this.totalPages = this.api.paginationGetTotalPages();
        this.numberOfRows = this.api.paginationGetRowCount();
        this.pageSizeInfo = `${this.currentPage === 1 ? 1 : page * this.currentPageSize + 1} to ${
          this.totalPages === 1 || this.currentPage === this.totalPages
            ? this.numberOfRows
            : this.api.paginationGetPageSize() * this.currentPage
        } of ${this.numberOfRows}`;
      });
    }
  }

  goToFirst() {
    this.api.paginationGoToFirstPage();
  }

  goToLast() {
    this.api.paginationGoToLastPage();
  }

  goToNextPage() {
    this.api.paginationGoToNextPage();
  }

  goToPreviousPage() {
    this.api.paginationGoToPreviousPage();
  }

  setPaginationSize(event: Event) {
    this.currentPageSize = Number((<HTMLSelectElement>event.target).value);
    (<any>this.api).gridOptionsWrapper.setProperty('cacheBlockSize', this.currentPageSize);
    this.api.paginationSetPageSize(this.currentPageSize);
    if (this.dataSource) {
      this.api.setServerSideDatasource(this.dataSource);
    }
  }

  goToPage(page: number) {
    this.api.paginationGoToPage(page);
  }
}
