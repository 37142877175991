import { Component, AfterViewInit, ViewChildren, OnDestroy } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { cleanNumber } from '../../helpers/form-mask-helpers';
import { FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { ErrorStateManagerService } from '../../../core/services/error-state-manager.service';

@Component({
  moduleId: module.id,
  selector: 'numeric-cell-editor',
  templateUrl: 'numeric-cell-editor.component.html',
  styleUrls: ['numeric-cell-editor.component.scss'],
})
export class NumericCellEditorComponent implements ICellEditorAngularComp, AfterViewInit, OnDestroy {
  @ViewChildren('input') input;
  value: string;
  maxValue: number;
  minValue: number;
  maxLength: number;
  minLength: number;
  numberMask = createNumberMask({ prefix: '', allowDecimal: false });
  multiplesOf: number;
  valueControl: FormControl;
  errorMessage: string;
  hasError: boolean;

  private params: any;

  constructor(private errorStateManager: ErrorStateManagerService) {}

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
    this.maxValue = this.params.maxLength;
    this.maxLength = this.params.printed ? this.maxValue.toString().length + 2 : this.maxValue.toString().length + 1;
    this.minValue = this.params.minLength;
    this.minLength = this.minValue.toString().length;
    this.multiplesOf = this.params.multiplesOf;
    this.errorMessage = this.params.errorMessage;
    const validatorFns: ValidatorFn[] = [this.rangeValidator.bind(this, this.minValue, this.maxValue)];
    if (this.multiplesOf) {
      validatorFns.push(this.valueMultipleOfValidator.bind(this, this.multiplesOf));
    }
    this.valueControl = new FormControl(this.value, validatorFns);
    this.hasError = this.errorStateManager.hasError(this.params.node);
    this.valueControl.markAsTouched();
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.input.first.nativeElement.focus();
    });
  }

  getValue(): any {
    return this.valueControl.value;
  }

  isCancelAfterEnd(): boolean {
    return false;
  }

  ngOnDestroy() {
    /* istanbul ignore else */
    if (this.valueControl.valid || this.valueControl.value === '') {
      this.errorStateManager.cleanErrorState(this.params.node);
      if (this.params.node.parent.data) {
        this.errorStateManager.cleanErrorState(this.params.node.parent);
      }
      return;
    }
    this.errorStateManager.setError(this.params.node);
    /* istanbul ignore else */
    if (this.params.node.parent.data) {
      this.errorStateManager.setError(this.params.node.parent);
    }
  }

  valueMultipleOfValidator(multiple: number, control: AbstractControl) {
    return cleanNumber(control.value) % multiple > 0 ? { notMultiple: true } : null;
  }

  rangeValidator(min: number, max: number, control: AbstractControl) {
    const asNumber = cleanNumber(control.value);
    return asNumber > max || asNumber < min ? { notInRange: true } : null;
  }
}
