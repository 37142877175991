import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomerInfo } from '../../models/customer-info';
import { catchError, finalize } from 'rxjs/operators';
import { CustomerApiService, GetAcctBasicDetailsPath, GetAcctBasicDetailsResp } from '@xpo-ltl/sdk-customer';
import { ErrorStateMatcher } from '@angular/material';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'app-mad-input',
  templateUrl: './mad-input.component.html',
  styleUrls: ['./mad-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MadInputComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MadInputComponent),
      multi: true,
    },
  ],
})
export class MadInputComponent implements OnInit, AfterViewChecked, ControlValueAccessor {
  constructor(
    private customerService: CustomerApiService,
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
    private changeDetector: ChangeDetectorRef
  ) {}

  customers: CustomerInfo[] = [];
  isLoading: boolean;
  preLoading: boolean;
  control: FormControl;
  errorMatcher = new CustomErrorStateMatcher();
  inputValue: string;
  isSelection: boolean = false;

  @Input() formControlName: string;
  @Output() change = new EventEmitter<CustomerInfo>();

  @ViewChild('madInput', { static: false }) madInput: ElementRef;

  onChange: Function = () => {};

  onTouched: Function = () => {};

  ngOnInit(): void {
    this.getControl();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  private getControl() {
    /* istanbul ignore else */
    if (this.controlContainer) {
      if (this.formControlName) {
        this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        this.control.clearValidators();
      } else {
        console.warn('Missing FormControlName directive from component host element');
      }
    } else {
      /* istanbul ignore next */
      console.warn('Can\'t find parent FormGroup directive');
    }
  }

  writeValue(customer: string): void {
    /* istanbul ignore else */
    if (!this.control.touched && customer) {
      this.preLoading = true;
      this.searchCustomer(customer, true);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate({ value }: FormControl): any {
    const validMadCode = !value || (this.customers && this.customers.some((c) => c.madCode === value));

    return validMadCode ? null : { invalid: true };
  }

  displayCustomerName(customer: string): string {
    if (this.customers && this.customers.length > 0) {
      return this.customers.find((c) => c.madCode === customer).viewValue;
    }
  }

  customerSelected(): void {
    this.madInput.nativeElement.blur();
    this.change.emit(this.customers[0]);
  }

  searchCustomer(value: string, unTouched?: boolean): void {
    this.customers = [];
    /* istanbul ignore else */
    if (value.length === 11) {
      const pathParams: GetAcctBasicDetailsPath = {
        acctInstId: value,
      };
      this.isLoading = true;
      this.customerService
        .getAcctBasicDetails(pathParams)
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.preLoading = false;
          }),
          catchError(() => EMPTY)
        )
        .subscribe((response: GetAcctBasicDetailsResp) => {
          if (response && ['C', 'P'].some((code) => code === response.acctBasicDetails.acctIdInfo.acctPartyRole)) {
            const acctMadCd = response.acctBasicDetails.acctIdInfo.acctMadCd;
            const acctName = response.acctBasicDetails.acctIdInfo.acctName;
            this.customers = [
              {
                viewValue: `${acctMadCd} - ${acctName}`,
                madCode: response.acctBasicDetails.acctIdInfo.acctMadCd,
                customerType: response.acctBasicDetails.acctIdInfo.acctPartyRole,
                postalCd: response.acctBasicDetails.acctNameAddr.postalCd,
              },
            ];
            /* istanbul ignore else */
            if (unTouched) {
              this.control.markAsTouched();
              this.control.setValue(this.customers[0].madCode);
              this.customerSelected();
            }
          }
        });
    }
  }
}

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}
