import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private snackbar: XpoSnackBar) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const errorCodes = ['PRON021-005E', 'PRON021-002E', 'PRON021-006E', 'PRON999-001E'];

    return next.handle(req).pipe(
      catchError((resp: any) => {
        const errorObject = resp.error || resp;
        if (errorObject.error && errorCodes.includes(errorObject.error.errorCode)) {
          this.snackbar.error(errorObject.error.message);
        }
        return throwError(resp);
      })
    );
  }
}
