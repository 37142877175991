import {
  Component,
  Output,
  EventEmitter,
  ElementRef,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
  ViewChildren,
  ViewChild,
} from '@angular/core';
import { ProPadTypeCd } from '@xpo-ltl/sdk-common';
import { UnsubscribeComponent } from '../unsubscribe/unsubscribe.component';
import { ProInventoryService } from '../../../core/services/pro-inventory.service';
import _moment from 'moment';
import { UserService } from '../../../core/services/user.service';

@Component({
  selector: 'note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent extends UnsubscribeComponent implements OnChanges {
  @Input() data: any = null;
  @Input() open: boolean;
  @Input() savingNote: boolean;
  @Output() closeEvent = new EventEmitter<any>();
  @Output() saveNoteEvent = new EventEmitter<any>();
  @ViewChild('note', { static: false }) note: ElementRef;

  noteText: string = '';

  constructor(
    private proInventoryService: ProInventoryService,
    private userService: UserService,
    private el: ElementRef
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.open && changes.open.currentValue) {
      setTimeout(() => {
        this.note.nativeElement.focus();
      }, 50);
    }
    if (changes.data && changes.data.currentValue) {
      this.noteText = changes.data.currentValue.note || '';
    }
  }

  closeSideBar() {
    this.closeEvent.emit();
  }

  textLabelCd(code: ProPadTypeCd) {
    switch (code) {
      case ProPadTypeCd.US_BLUE_YELLOW:
        return 'US, B&Y';
      case ProPadTypeCd.CA_BLUE_YELLOW:
        return 'CA, B&Y';
      case ProPadTypeCd.US_BLUE:
        return 'US, Blue';
      case ProPadTypeCd.CA_BLUE:
        return 'CA, Blue';
      case ProPadTypeCd.YELLOW:
        return 'Yellow';
    }
  }

  saveNote(text: string) {
    this.saveNoteEvent.emit({ order: this.data, note: text });
  }
}
