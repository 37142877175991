import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-text-collapse',
  templateUrl: './text-collapse.component.html',
  styleUrls: ['./text-collapse.component.scss'],
})
export class TextCollapseComponent implements OnInit {
  @Input() text: string;

  buttonText = 'Show More';
  shouldCollapse: boolean;
  collapsed: boolean;
  showText: string;

  constructor() {}

  ngOnInit() {
    this.collapsed = false;
    this.shouldCollapse = this.text && this.text.length > 35;
    this.showText = this.shouldCollapse ? this.text.slice(0, 28) : this.text;
  }

  toggleExpand() {
    this.collapsed = !this.collapsed;
    this.buttonText = this.collapsed ? 'Show Less' : 'Show More';
    this.showText = !this.collapsed ? this.text.slice(0, 28) : this.text;
  }
}
