import { Component, Input } from '@angular/core';
import { UnsubscribeComponent } from '../unsubscribe/unsubscribe.component';
import { HelpModalComponent } from './help-modal.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss'],
})
export class FooterComponent extends UnsubscribeComponent {
  @Input() build: string;
  @Input() drawer: boolean;

  constructor(private dialog: MatDialog) {
    super();
  }

  showHelp(): void {
    this.dialog.open(HelpModalComponent, { width: '500px' });
  }
}
