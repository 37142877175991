import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import moment, { Moment } from 'moment';
import { DateRange } from '../../shared/models/date-range';
import { DateRangePosition } from '../../shared/enums/date-range-positions.enum';

@Injectable({
  providedIn: 'root',
})
export class DateRangeService {
  private directionSubject = new BehaviorSubject<string | Moment>(null);
  private selectedDateSubject = new BehaviorSubject<DateRange>(null);
  private currentRange = new Map<DateRangePosition, Moment | null>();

  private selectedDatesCount = 0;

  constructor() {
    this.currentRange.set(DateRangePosition.End, null);
    this.currentRange.set(DateRangePosition.Start, null);
  }

  direction() {
    return this.directionSubject;
  }

  selectedDate() {
    return this.selectedDateSubject;
  }

  nextDirection(dir: string | Moment) {
    this.directionSubject.next(dir);
  }

  nextDate(startDate: Moment, endDate?: Moment) {
    let position: DateRangePosition;
    const newDay = startDate.clone().startOf('day');

    switch (this.selectedDatesCount) {
      case 0:
        position = DateRangePosition.Start;
        this.currentRange.set(position, newDay);
        break;
      case 1:
        if (this.currentRange.get(DateRangePosition.Start).isAfter(newDay)) {
          position = DateRangePosition.Start;
          this.currentRange.set(position, newDay);
        } else {
          position = DateRangePosition.End;
          this.currentRange.set(position, newDay);
          this.selectedDatesCount++;
        }
        break;
      case 2:
        if (this.currentRange.get(DateRangePosition.End).isSameOrAfter(newDay, 'day')) {
          position = DateRangePosition.Start;
          if (this.currentRange.get(DateRangePosition.Start).isSame(newDay, 'day')) {
            break;
          }
          this.currentRange.set(position, newDay);
        } else {
          position = DateRangePosition.Start;
          this.currentRange.set(position, newDay);
          this.currentRange.set(DateRangePosition.End, null);
          this.selectedDatesCount = 1;
        }
        break;
    }

    if (endDate) {
      this.currentRange.set(DateRangePosition.End, endDate.clone());
    }

    this.selectedDateSubject.next(this.getRange());
  }

  isInRange(date: Moment): boolean {
    if ([...this.currentRange.values()].every((v) => !!v)) {
      return date.isBetween(
        this.currentRange.get(DateRangePosition.Start),
        this.currentRange.get(DateRangePosition.End),
        'day',
        '()'
      );
    } else {
      return false;
    }
  }

  isDaySelected(date: Moment): boolean {
    return [...this.currentRange.values()].some(
      (v) =>
        v &&
        date
          .clone()
          .startOf('day')
          .isSame(v)
    );
  }

  getRange(): DateRange {
    return {
      startDate: this.currentRange.get(DateRangePosition.Start),
      endDate: this.currentRange.get(DateRangePosition.End),
    };
  }

  setInitialDates(start: Moment, end: Moment) {
    this.currentRange.set(DateRangePosition.Start, start.clone().startOf('day'));
    this.currentRange.set(DateRangePosition.End, end.clone().startOf('day'));
    this.selectedDatesCount = 2;
  }

  allDatesSelected(): boolean {
    return [...this.currentRange.values()].every((v) => !!v);
  }
}
