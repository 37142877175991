import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyNumbers]',
})
export class OnlyNumbersDirective {
  @Input() allowDecimals: boolean;
  @Input() isDate: boolean;
  constructor() {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    let regex = this.allowDecimals ? /\d|\./ : /\d/;

    const ctrl = event.ctrlKey;
    const cmd_held = event.metaKey;

    if (
      !(
        ctrl ||
        cmd_held ||
        (event.key === '86' && ctrl) ||
        (event.key === '67' && ctrl) ||
        (event.key === '86' && cmd_held) ||
        (event.key === '67' && cmd_held)
      )
    ) {
      regex = this.isDate ? /\d|\// : regex;
      if (
        [
          !regex.test(event.key),
          !event.key.includes('Arrow'),
          event.key !== 'Backspace',
          event.key !== 'Tab',
          event.key !== 'Shift',
        ].reduce((acc, curr) => acc && curr, true)
      ) {
        event.preventDefault();
      }
    }
  }
}
