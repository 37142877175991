import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProPadTypeCd } from '@xpo-ltl/sdk-common';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogResult } from '../../shared/models/dialog-result';

@Component({
  moduleId: module.id,
  selector: 'confirm-request-dialog',
  templateUrl: 'confirm-request-dialog.component.html',
  styleUrls: ['confirm-request-dialog.component.scss'],
})
export class ConfirmRequestDialogComponent implements OnInit {
  requestDialogForm: FormGroup;
  confirmMessage: string;
  confirmText: string;
  cancelText: string;
  title: string;
  reasonTitle: string;
  paletteColour: string;
  quantities: [];
  reasonMax = 500;
  reasonText: string;
  reasonRequired: boolean;
  labelTypes: { type: string; imageUrl: string; name: string }[] = [
    { type: ProPadTypeCd.CA_BLUE_YELLOW, imageUrl: 'blue-yellow-label.png', name: 'CA, Blue & Yellow' },
    { type: ProPadTypeCd.US_BLUE_YELLOW, imageUrl: 'blue-yellow-label.png', name: 'US, Blue & Yellow' },
    { type: ProPadTypeCd.CA_BLUE, imageUrl: 'blue-label.png', name: 'CA, Blue' },
    { type: ProPadTypeCd.US_BLUE, imageUrl: 'blue-label.png', name: 'US, Blue' },
    { type: ProPadTypeCd.YELLOW, imageUrl: 'yellow-label.png', name: 'Yellow' },
  ];
  noImage: boolean;

  constructor(
    public dialogRef: MatDialogRef<ConfirmRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    const {
      confirmMessage,
      confirmText,
      cancelText,
      title,
      reasonTitle,
      paletteColour,
      quantities,
      reasonRequired,
    } = this.dialogData;
    this.confirmMessage = confirmMessage;
    this.confirmText = confirmText;
    this.cancelText = cancelText;
    this.title = title;
    this.reasonTitle = reasonTitle;
    this.paletteColour = paletteColour;
    this.quantities = quantities;
    this.reasonRequired = reasonRequired;
    this.requestDialogForm = this.createForm();
  }

  private createForm() {
    const validators: Validators[] =
      this.reasonRequired || (this.quantities && this.quantities.length > 0)
        ? [Validators.required, Validators.maxLength(this.reasonMax), this.noWhitespaceValidator]
        : [];
    return this.fb.group({
      reasonText: [{ value: '', disabled: false }, validators],
    });
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  getImageName(type) {
    const matchingLabelType = this.labelTypes.find((x) => x.type === type);
    if (matchingLabelType) {
      return matchingLabelType.imageUrl;
    } else {
      this.noImage = true;
      return null;
    }
  }

  confirm() {
    const reason = this.requestDialogForm.controls['reasonText'].value;
    const result: DialogResult = { reason: reason, confirmed: true };
    this.dialogRef.close(result);
  }

  cancel() {
    const result: DialogResult = { confirmed: false };
    this.dialogRef.close(result);
  }

  getLabelName(type) {
    const matchingLabelType = this.labelTypes.find((x) => x.type === type);
    return matchingLabelType ? matchingLabelType.name : '';
  }
}
