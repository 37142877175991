import { NgModule } from '@angular/core';
import {
  MatIconModule,
  MatDialogModule,
  MatInputModule,
  MatFormFieldModule,
  MatButtonModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSelectModule,
  MatProgressSpinnerModule,
  MatButtonToggleModule,
  MatCardModule,
  MatTooltipModule,
  MatSidenavModule,
  MatDividerModule,
  MatCheckboxModule,
  MatSlideToggleModule,
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule, MatTableModule } from '@angular/material';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
  declarations: [],
  providers: [],
  exports: [
    MatProgressSpinnerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    FlexLayoutModule,
    MatButtonToggleModule,
    MatCardModule,
    MatTooltipModule,
    MatSidenavModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatTableModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatSlideToggleModule,
  ],
})
export class SharedVendorModule {}
