import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';
import { UnsubscribeComponent } from '../unsubscribe/unsubscribe.component';

@Component({
  selector: 'app-footer',
  template: `
    <h1 mat-dialog-title>
      HELP
      <xpo-dialog-close-button></xpo-dialog-close-button>
    </h1>
    <div mat-dialog-content>
      <p>
        For help, please contact <b>LTL Application Support:</b><br />
        <br />
        EMAIL ADDRESS<br />
        <a href="mailto:LTLApplicationSupport@xpo.com">LTLApplicationSupport@xpo.com</a><br />
        <br />
        PHONE NUMBER<br />
        (503) 450 2020
      </p>
    </div>
    <div mat-dialog-actions>
      <button mat-flat-button style="float: left;" mat-flat-button (click)="close()">OK</button>
    </div>
  `,
})
export class HelpModalComponent extends UnsubscribeComponent {
  constructor(public dialogRef: MatDialogRef<HelpModalComponent>) {
    super();
  }

  close(): void {
    this.dialogRef.close();
  }
}
