import { Component, ViewChildren, OnDestroy, AfterViewInit } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import moment from 'moment';
import { ErrorStateManagerService } from '../../../core/services/error-state-manager.service';
import { cleanNumber } from '../../helpers/form-mask-helpers';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
})
export class TextEditorComponent implements ICellEditorAngularComp, OnDestroy, AfterViewInit {
  @ViewChildren('input') input;

  valueControl: FormControl;
  classes;
  isCurrency: boolean = false;
  maxLength: number;
  isDate: boolean;
  minValue: number = 1;
  maxValue: number = 999999999;

  private params: any;

  constructor(private errorStateManager: ErrorStateManagerService) {}

  getValue() {
    return this.valueControl.value;
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.input.first.nativeElement.focus();
    });
  }

  isCancelAfterEnd() {
    return false;
  }

  agInit(params) {
    const validators: ValidatorFn[] = [Validators.maxLength(params.maxLength)];
    this.params = params;
    this.isDate = params.isDate;
    this.isCurrency = params.isCurrency;
    this.maxLength = params.maxLength;
    this.classes = {
      currency: params.isCurrency,
    };
    if (this.isDate) {
      validators.push(this.textDateValidator);
    }
    if (this.isCurrency) {
      validators.push(this.rangeValidator.bind(this, this.minValue, this.maxValue));
    }
    this.valueControl = new FormControl(params.value, validators);
  }

  textDateValidator(control: AbstractControl) {
    return moment(control.value, 'L', true).isValid() ? null : { invalidDate: true };
  }

  rangeValidator(min: number, max: number, control: AbstractControl) {
    const asNumber = cleanNumber(control.value);
    return asNumber > max || asNumber < min ? { notInRange: true } : null;
  }

  ngOnDestroy() {
    if (this.valueControl.valid || this.valueControl.value === null || this.valueControl.value === '') {
      this.errorStateManager.cleanErrorState(this.params.node);
      this.errorStateManager.cleanErrorState(this.params.column.colId + this.params.node.data.id);
    } else {
      this.errorStateManager.setError(this.params.node);
      this.errorStateManager.setError(this.params.node, this.params.column.colId + this.params.node.data.id);
    }
  }
}
